import React from 'react'
import { keyframes } from 'styled-components'
import { random } from 'lodash/fp'

import { Box, Text } from 'src/components/system'

const scaleUp = keyframes`
  from {
    box-shadow: 0 5px 7.5px rgba(0, 0, 0, 0);
    transform: scale(1);
  }

  to {
    box-shadow: 0 5px 7.5px rgba(0, 0, 0, 0.25);
    transform: scale(1.1);
  }
`

export class MagnetStrip extends React.Component {
  constructor() {
    super()

    this.state = {
      rotation: random(-2.5, 2.5, true),
    }
  }

  render = () => {
    const {
      children,
      isFloating,
      withRotation,
      inverted = false,
      ...props
    } = this.props
    const { rotation } = this.state

    return (
      <Box
        bg={inverted ? 'white' : 'tealLight'}
        color={inverted ? 'tealLight' : 'white'}
        cursor="pointer"
        display="inline-block"
        p={2}
        css={`
          transform: rotate(${withRotation ? rotation : 0}deg);
          animation: ${isFloating ? scaleUp : 'none'} 250ms forwards;
          user-select: none;
        `}
        {...props}
      >
        <Text
          fontSize={['midLarge', null, null, 'large']}
          whiteSpace="nowrap"
          css={`
            transform: translateY(5%);
          `}
        >
          {children}
        </Text>
      </Box>
    )
  }
}
