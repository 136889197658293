import React from 'react'

import { Box, Flex, Text } from 'src/components/system'
import { Heading } from 'src/components/Heading'
import { Link } from 'src/components/Link'
import { SVG } from 'src/components/SVG'
import PatternDiagonalLines from 'src/assets/pattern-diagonal-lines.png'
import { ReactComponent as AssetLogoWWSVG } from 'src/assets/logo-ww.svg'

const SecondaryText = props => (
  <Text
    color="tealExtraLight"
    fontFamily="sans"
    fontSize="midLarge"
    textAlign="center"
    {...props}
  />
)

export const Footer = props => (
  <Flex
    backgroundImage={`url(${PatternDiagonalLines})`}
    backgroundPosition="center center"
    backgroundSize="auto 2rem"
    bg="white"
    justifyContent={[null, 'center']}
    py={8}
  >
    <Box
      as={Link}
      id="logo"
      to="https://walltowall.com"
      target="_blank"
      rel="nofollow noreferrer"
      flex={['1 1 auto', '0 0 auto']}
      bg="white"
      py={2}
      px={[2, 4]}
    >
      <Flex
        display={['flex', 'inline-flex']}
        flexDirection={['column', 'row']}
        alignItems="center"
        justifyContent={[null, 'center']}
      >
        <SecondaryText display={['none', 'block']}>HNL</SecondaryText>
        <Heading
          color="teal"
          mb={[1, 0]}
          textAlign="center"
          fontSize="large"
          mx={[null, 6]}
        >
          Wall-to-Wall Studios
        </Heading>
        <Text
          color="tealExtraLight"
          fontFamily="sans"
          fontSize="midLarge"
          textAlign="center"
          display={['block', 'none']}
        >
          HNL &middot; PGH
        </Text>
        <SecondaryText display={['none', 'block']}>PGH</SecondaryText>
      </Flex>
    </Box>
  </Flex>
)
