import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { get } from 'lodash/fp'

import { theme } from 'src/theme'
import { Box, Flex, Text } from 'src/components/system'
import { ArchiveBar } from 'src/components/ArchiveBar'
import { AspectRatio } from 'src/components/AspectRatio'
import { DevRefreshButton } from 'src/components/DevRefreshButton'
import { Footer } from 'src/components/Footer'
import { OutputRenderer } from 'src/components/OutputRenderer'
import AssetTextureTeal from 'src/assets/texture-teal.png'

import 'minireset.css'
import 'src/index.css'

const render = props => queryData => (
  <>
    <Helmet
      titleTemplate={`%s – ${get('site.siteMetadata.title', queryData)}`}
      defaultTitle={get('site.siteMetadata.title', queryData)}
    >
      <html lang="en" />
      <meta
        name="description"
        content={get('site.siteMetadata.description', queryData)}
      />
      <meta name="robots" content="noindex" />
      <link rel="stylesheet" href="https://use.typekit.net/fpw6fqv.css" />
    </Helmet>
    <ThemeProvider theme={theme}>
      <Text
        as="div"
        color="white"
        fontFamily="sansCond"
        fontSize="medium"
        fontWeight="bold"
        lineHeight="solid"
        textStyle="smallCaps"
      >
        <ArchiveBar />
        {process.env.NODE_ENV === 'development' && <DevRefreshButton />}
        <Box
          left={0}
          position="absolute"
          top={0}
          width="100%"
          css={`
            transform: translate(-110%, -110%);
            pointer-events: none;
          `}
        >
          <Box id="previewImage" width={['100%', 400, 600, 800]}>
            <OutputRenderer />
          </Box>
          <AspectRatio
            id="ogImage"
            x={1200}
            y={630}
            bg="teal"
            backgroundImage={`url(${AssetTextureTeal})`}
            backgroundSize="auto 100%"
            backgroundPosition="center center"
            width={[762, null, 1142, 1523]}
          >
            <Flex alignItems="center" justifyContent="center">
              <Box width={[400, null, 600, 800]}>
                <OutputRenderer />
              </Box>
            </Flex>
          </AspectRatio>
        </Box>
        <Box as="main" {...props} />
        <Footer />
      </Text>
    </ThemeProvider>
  </>
)

export const Layout = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={render(props)}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
