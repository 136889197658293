import React from 'react'

import { Box, Flex, Text } from 'src/components/system'
import { SVG } from 'src/components/SVG'
import { Link } from 'src/components/Link'
import { ReactComponent as IconTriangleRightSVG } from 'src/assets/icon-triangle-right.svg'
import { ReactComponent as IconTriangleLeftSVG } from 'src/assets/icon-triangle-left.svg'

import './ArchiveBar.css'

export const ArchiveBar = () => (
  <div className="archive-bar">
    <div className="archive-bar__item">2018</div>
    <a href="/2017">
      <div className="archive-bar__item">
        See the previous year
        <svg viewBox="0 0 8 12" class="archive-bar__previous-year__arrow">
          <path d="M8 6l-8 6V0z" fill-rule="evenodd" />
        </svg>
      </div>
    </a>
  </div>
)
