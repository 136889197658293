import React from 'react'
import { connect } from 'react-redux'
import { get, map, find, compose, merge, isEmpty } from 'lodash/fp'
import { useMedia } from 'react-use-media'

import { toPercentage } from 'src/helpers'
import { Box, Flex, Text, Image } from 'src/components/system'
import { AspectRatio } from 'src/components/AspectRatio'
import { DraggableMagnetStrip } from 'src/components/DraggableMagnetStrip'
import { MagnetStrip } from 'src/components/MagnetStrip'
import AssetBackground1 from 'src/assets/background-1.jpg'
import AssetBackground2 from 'src/assets/background-2.jpg'
import AssetBackground3 from 'src/assets/background-3.jpg'
import AssetBackground4 from 'src/assets/background-4.jpg'

const EmptyMessage = ({ isEmpty, isOver, ...props }) => {
  const isDesktop = useMedia({ minWidth: '49rem' })

  return (
    <Flex
      position="relative"
      height="100%"
      alignItems="center"
      justifyContent="center"
      p={[6, null, 12]}
      opacity={isEmpty && !isOver ? 1 : 0}
      css={`
        transition: opacity ${p => p.theme.transition};
        transform: translateY(3%);
      `}
      {...props}
    >
      <Text
        color="teal"
        textAlign="center"
        fontSize={['large', null, null, 'xlarge']}
        lineHeight="title"
        maxWidth={['20rem', null, null, '30rem']}
      >
        Build your
        <MagnetStrip
          cursor="auto"
          position="absolute"
          css={`
            transform: translate(-20%, -95%) rotate(-5deg);
          `}
        >
          occasionally
        </MagnetStrip>{' '}
        original message here using the words provided{' '}
        {isDesktop ? 'on the right' : 'below'}.
      </Text>
    </Flex>
  )
}

const backgrounds = {
  1: AssetBackground1,
  2: AssetBackground2,
  3: AssetBackground3,
  4: AssetBackground4,
}

const OutputRendererBase = ({
  isOver = false,
  magnets,
  magnetsDraggable = false,
  background = 1,
  ...props
}) => {
  const MagnetStripComp = magnetsDraggable ? DraggableMagnetStrip : MagnetStrip
  const backgroundImage = get(background, backgrounds)

  return (
    <AspectRatio x={1} y={1} width="100%">
      <Box
        bg="white"
        height="100%"
        overflow="hidden"
        position="relative"
        css={`
          user-select: none;
        `}
        {...props}
      >
        <Image
          src={backgroundImage}
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
        />
        <EmptyMessage isEmpty={isEmpty(magnets)} isOver={isOver} />
        {map(
          magnet => (
            <MagnetStripComp
              key={get('id', magnet)}
              id={get('id', magnet)}
              position="absolute"
              top={compose(
                toPercentage,
                get('y')
              )(magnet)}
              left={compose(
                toPercentage,
                get('x')
              )(magnet)}
              withRotation={true}
            >
              {get('value', magnet)}
            </MagnetStripComp>
          ),
          magnets
        )}
      </Box>
    </AspectRatio>
  )
}

const mapStateToProps = state => ({
  magnets: compose(
    map(position => {
      const magnet = compose(
        find(['id', get('id', position)]),
        get('magnets.items')
      )(state)

      return merge(magnet, position)
    }),
    get('magnets.positions')
  )(state),
  background: get('refrigerator.background', state),
})

export const OutputRenderer = connect(mapStateToProps)(OutputRendererBase)
