import React from 'react'
import { findDOMNode } from 'react-dom'
import { DragSource } from 'react-dnd'
import { compose } from 'lodash/fp'

import { ItemTypes } from 'src/constants'
import { MagnetStrip } from 'src/components/MagnetStrip'

const magnetStripSource = {
  beginDrag: props => ({
    id: props.id,
    value: props.children,
  }),
}

const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
})

const EnhancedMagnetStrip = ({
  connectDragSource,
  connectDragPreview,
  isDragging = false,
  ...props
}) => (
  <MagnetStrip
    ref={compose(
      connectDragPreview,
      connectDragSource,
      findDOMNode
    )}
    opacity={isDragging ? 0 : 1}
    cursor={isDragging ? 'grabbing' : 'grab'}
    {...props}
  />
)

export const DraggableMagnetStrip = DragSource(
  ItemTypes.MAGNET_STRIP,
  magnetStripSource,
  collect
)(EnhancedMagnetStrip)
