import React from 'react'
import { divide } from 'lodash/fp'

import { Box } from 'src/components/system'

export const AspectRatio = ({ x, y, children, css, ...props }) => (
  <Box
    position="relative"
    css={{
      '&:before': {
        content: `''`,
        display: 'block',
        paddingTop: divide(y, x) * 100 + '%',
        width: '100%',
      },
    }}
    {...props}
  >
    <Box position="absolute" top={0} left={0} right={0} bottom={0}>
      {children}
    </Box>
  </Box>
)
