import React from 'react'

import { SVG as SVGBase } from 'src/components/system'
import { AspectRatio } from 'src/components/AspectRatio'

export const SVG = ({ svg, fill, ...props }) => (
  <AspectRatio {...props}>
    <SVGBase as={svg} fill={fill} width="100%" height="100%" />
  </AspectRatio>
)
